@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Urbane-Light;
  src: url('./fonts/urbane-light.otf') format('opentype');
  src: url('./fonts/urbane-light-webfont.woff') format('woff');
}

@font-face {
  font-family: Urbane-ExtraLight;
  src: url('./fonts/urbane-extralight.otf') format('opentype');
  src: url('./fonts/urbane-extralight-webfont.woff') format('woff');
}

@font-face {
  font-family: Urbane-Medium;
  src: url('./fonts/urbane-medium.otf') format('opentype');
  src: url('./fonts/urbane-medium-webfont.woff') format('woff');
}

@font-face {
  font-family: Urbane-DemiBold;
  src: url('./fonts/urbane-demibold.otf') format('opentype');
  src: url('./fonts/urbane-demibold-webfont.woff') format('woff');
}

.urbane-extralight {
  font-family: 'Urbane-ExtraLight';
}

.urbane-light {
  font-family: 'Urbane-Light';
}

.urbane-medium {
  font-family: 'Urbane-Medium';
}

.urbane-demibold {
  font-family: 'Urbane-DemiBold';
}


body {
  height:100%;
  margin: 0;
  font-family: 'Urbane-Light', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
  height:100%;
  overflow: auto;
}


@layer utilities{
  .apply-filter-button{
    padding: .375rem .75rem;
    border: 1px solid #0079EA;
    border-radius: .25rem;
    margin-right: .5rem;
    margin-bottom: .5rem;
    background-color:#FFF;
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    color:#0079EA;
    font-family:'urbane-medium';
  }

  .filter-input{
    width:80%;
    height: 3rem;
    margin: 0 1rem 1rem 0;
    border-radius: .25rem;
    border: 2px solid #000;
    padding: 0 1rem;
  }

  .filter-input:focus{
    outline:none;
    border: 1px solid #0079EA;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .endorsements-scroll{
    height: calc(100% - 7.5rem);
  }

  .vouches-scroll{
    height: calc(100% - 5rem);
  }

  .messaging-container{
    height: calc(100% - 4rem);
  }

  .shadow-top{
    box-shadow: 0 -10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

  .menu-icon:hover .dropdown-menu{
  display:block;

  }

  .logout-item:hover .logout-icon{
    display:none;
  }

  .logout-item:hover .logout-icon-white{
    display:block;
  }

  .paginator-container{
    display:flex;
    flex-direction:row;
    justify-content:center;
  }

  .paginator-container li{
    background-color: #FFF;
    border-radius:4px;
    margin: 0px 4px;

  }

  .paginator-container li a{
    padding:2px 6px;
  }

  .paginator-container li.active{
    background-color:#0079EA;
    color:#FFF;

  }

}

