.bg-unread-circle {
    background-color: #0079EA;
}

.unread-messages-bubble {
    background-color: #0079EA;
    position: absolute;
    top: -5px;
    right: 55px;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: .75rem;

    display: flex;
    justify-content: center;
    align-items: center;
}